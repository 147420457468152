<template>
    <div class="wrapper wrapper-full-page">
        <div class="full-page section-image"
             
             filter-color="black"
             data-image="/img/bg16.jpg">
            
            <div class="content">
                <div class="container">
                    <router-view></router-view>
                </div>
            </div>

            <div class="full-page-background"
                 style="background-image: url('/img/bg16.jpg')">
            </div>

        </div>
    </div>
</template>

<script>
</script>

<style lang="scss">
</style>
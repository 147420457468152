import axios from 'axios';
import { BaseUrl } from '@/appsettings.json';

//export const HTTP = axios.create({
//  baseURL: process.env.VUE_APP_API_ENDPOINT    
//});

export const HTTP = axios.create({
    baseURL: BaseUrl
});

<template>
    <Form @submit="onSubmit" :validation-schema="schema">
        <div class="row">

            <div class="col-lg-5 col-md-8 col-xs-12 ml-auto mr-auto" v-show="getCard">
                <vnud-card class="card-pricing" no-footer-line>
                    <div class="card-icon icon-primary">
                        <i class="now-ui-icons shopping_credit-card"></i>
                    </div>
                    <h3 class="card-title">Gavekort Saldo</h3>

                    <div>
                        <vee-input name="CardNumber"
                                   type="text"
                                   inputClasses="no-border form-control-lg"
                                   placeholder="Kortnummer - 16 cifre f.eks. 4710410000000001"
                                   addon-left-icon="now-ui-icons ui-1_email-85" />

                        <vee-input name="Cvc"
                                   type="text"
                                   inputClasses="no-border form-control-lg"
                                   placeholder="Sikkerhedskode - et tal imellem 1 og 999 f.eks. 12"
                                   addon-left-icon="now-ui-icons ui-1_lock-circle-open" />
                    </div>

                    <template v-slot:footer>
                        <n-button native-type="submit" type="primary" round block>
                            Hent saldo
                        </n-button>
                    </template>
                </vnud-card>
            </div>

            <div class="col-12" card-body-classes="table-full-width" v-show="showCardData">
                <vnud-card class="card-pricing" no-footer-line v-show="showCardData">
                    <div class="card-icon icon-primary">
                        <i class="now-ui-icons shopping_credit-card"></i>
                    </div>
                    <h3 class="card-title">Gavekort {{cardNumber}}</h3>

                    <template v-slot:footer>

                        <div class="row">
                            <div class="col-lg-5 col-md-8 ml-auto mr-auto">
                                <vnud-card class="card-pricing" no-footer-line>
                                    <div class="card-icon icon-warning">
                                        <i class="now-ui-icons business_bulb-63"></i>
                                    </div>
                                    <div>
                                        <h3 class="card-title">Saldo: {{ formatAmountFromValue(accountBalance) }}</h3>
                                        <h3>Kortet udløber: {{ formatDateFromValue(accountExpirationDate) }}</h3>
                                    </div>

                                </vnud-card>
                            </div>
                        </div>

                        <el-table stripe :data="transactions">
                            <el-table-column prop="transactionDateTime"
                                             label="Tidspunkt"
                                             :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="shopName"
                                             label="Butik">
                            </el-table-column>
                            <el-table-column prop="type"
                                             label="Transaktion">
                            </el-table-column>
                            <el-table-column prop="transactionAmount"
                                             label="Beløb"
                                             :formatter="formatAmount"
                                             align="right">
                            </el-table-column>
                            <el-table-column prop="balance"
                                             label="Saldo"
                                             :formatter="formatAmount"
                                             align="right">
                            </el-table-column>                            
                        </el-table>
                    </template>
                </vnud-card>
            </div>

        </div>
    </Form>
</template>

<script>
    import { VeeInput } from "@/components";
    import { Form } from "vee-validate";
    import * as Yup from "yup";
    import { HTTP } from '@/util/http-common';
    //import * as dates from '@/util/datePrototypes';
    import { ElTable, ElTableColumn } from "element-plus";
    import swal from 'sweetalert2';

    export default {
        name: "vnud-login",
        components: {
            VeeInput,
            Form,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
        },
        setup() {

            const schema = Yup.object().shape({
                CardNumber: Yup.string()
                    .required('Du skal taste et kortnummer')
                    .matches(/^471041\d{10}$/, 'Kortnummeret skal begynde med 471041 og have 10 cifre efterfølgende'),
                Cvc: Yup.string()
                    .required('Du skal taste en sikkerhedskode')
                    .min(1, 'Sikkerhedskoden skal minimum være 1')
                    .max(3, 'Sikkerhedskoden kan maks være 999'),
            });

            return {
                getCard: true,
                showCardData: false,
                schema,
            };
        },

        data() {
            return {
                success: false,
                cardNumber: 0,
                cardStatus: false,
                accountId: 0,
                accountStatus: false,
                accountExpirationDate: null,
                accountBalance: 0,
                transactions: [],
                message: '',
            };
        },

        methods: {

            onSubmit(values) {
                this.getCard = false;
                this.showCardData = true;
                let theBody = JSON.stringify(values, null, 2);
                HTTP
                    .post('/Api/Giftcard/GetCardholderBalance', theBody, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        let result = response.data;
                        this.success = result.success;
                        if (this.success) {
                            this.cardNumber = result.cardNumber;
                            this.cardStatus = result.cardStatus;
                            this.accountId = result.accountId;
                            this.accountStatus = result.accountStatus;
                            this.accountExpirationDate = result.accountExpirationDate;
                            this.accountBalance = result.accountBalance;
                            this.transactions = result.transactions;
                        }
                        else {
                            this.message = result.message;
                            swal.fire({
                                icon: 'error',
                                title: 'Kortet kan ikke vises',
                                html: this.message,
                                showConfirmButton: true,
                                confirmButtonText: '<i class="fa fa-bell"></i> LUK',
                                confirmButtonColor: 'red',
                                showCancelButton: false,
                                allowOutsideClick: false,
                            }).then((result) => {
                                location.reload();
                            });
                        }
                    })
                    .catch(error => {
                        swal.fire({
                            icon: 'error',
                            title: 'Kortet kan ikke vises',
                            html: error,
                            showConfirmButton: true,
                            confirmButtonText: '<i class="fa fa-bell"></i> LUK',
                            confirmButtonColor: 'red',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                    });
            },

            // function for formatting amount to danish local
            formatAmount(row, prop) {
                var num = parseFloat(row[prop.property]);
                const formatObj = {
                    style: "currency",
                    currency: "DKR"
                };
                var formattedNum = num.toLocaleString("da-DK", formatObj);
                return formattedNum;
            },

            // function for formatting amount to danish local
            formatAmountFromValue(value) {
                var num = parseFloat(value);
                const formatObj = {
                    style: "currency",
                    currency: "DKR"
                };
                var formattedNum = num.toLocaleString("da-DK", formatObj);
                return formattedNum;
            },

            // function for formatting date to danish local
            formatDate(row, prop) {
                const d = new Date(row[prop.property]);
                return d.toLocaleString('da-DK', { dateStyle: 'short', hourCycle: 'h24', timeStyle: 'short' });
            },

            // function for formatting date to danish local
            formatDateFromValue(date) {
                if (date) {
                    const d = new Date(date);
                    return d.toLocaleString('da-DK', { dateStyle: 'short' });
                }
            },

        },
    };
</script>

<style scoped>
</style>